import { Component, Vue } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIDataTable from "@/components/UIDataTable.vue";
import { DEFAULT_TABLE_OPTIONS, TableOptions } from "@/api/request";
import { SearchRequest } from "@/api/regist-stripe-shop/request";
import RegistStripeShopSearch, {
  ListItem
} from "@/store/regist-stripe-shop/search";
import MainShopGet from "@/store/main-shop/get";
import AppNameListGet from "@/store/app-name-list/get";
import Flash, { ErrorAlert } from "@/store/common/flash";
import WindowOpen from "@/utils/window-open";

@Component({
  components: { UlContentHeader, UlBreadcrumbs, UIDataTable }
})
export default class Index extends Vue {
  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "Stripe";
  headingSub = "stripe";
  breadCrumbs = [{ text: "設定 > Stripe > Stripeアプリ一覧", disabled: true }];
  // テーブルヘッダ（UIDataTableコンポーネントに渡す）
  tableHeaders = [
    { text: "店舗ID", value: "id" },
    { text: "店舗名", value: "name" },
    { text: "アプリ名", value: "appName" },
    { text: "加盟店登録状態", value: "stripeRegistStatus" },
    {
      label: "加盟店登録",
      text: "",
      value: "edit",
      sortable: false
    },
    {
      label: "確認",
      text: "",
      value: "action",
      sortable: false
    }
  ];

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  // ------------
  // 変動値
  // ------------

  // ローディングステータス
  isLoading = false;

  // 検索入力オプション
  inputOptions = {
    searchShopId: null as number | null,
    selectShopIds: null as number[] | null,
    selectAppShopIds: null as number[] | null,
    stripeRegistStatus: null as number | null
  } as SearchRequest;

  // テーブル検索オプション（UIDataTableコンポーネントに渡す）
  tableOptions = DEFAULT_TABLE_OPTIONS;

  // ------------

  /**
   * テーブルに表示するアイテムリスト（UIDataTableコンポーネントに渡す）
   */
  get tableItems() {
    return RegistStripeShopSearch.getItems;
  }

  /**
   * 総件数（UIDataTableコンポーネントに渡す）
   */
  get totalCount() {
    return RegistStripeShopSearch.getTotalCount;
  }

  get mainShopItems() {
    return MainShopGet.getItems;
  }

  get appItems() {
    return AppNameListGet.getItems;
  }

  statusItems = [
    { text: "未登録", value: 0 },
    { text: "登録済み", value: 1 }
  ];

  /**
   * createdライフサイクルフック（UIDataTableコンポーネントに渡す）
   */
  async created() {
    await RegistStripeShopSearch.restore(this.inputOptions);
    const request = RegistStripeShopSearch.getSearchRequest;
    this.inputOptions = request;
    request.page && (this.tableOptions = request);
    await this.search();
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await MainShopGet.clearResponse();
  }

  /**
   * ページング変更コールバック
   *
   * @param tableOptions TableOptions
   */
  async tableChangeCallback(tableOptions: TableOptions) {
    // 子コンポーネントへpagingOptionsの変更が通知される
    this.tableOptions = tableOptions;
    await this.search();
  }

  /**
   * 検索ボタンがクリックされた際のコールバック
   */
  async searchClickCallback() {
    await Flash.clear();
    await this.search();
  }

  /**
   * テーブル行の編集ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のListItem
   */
  async editClickCallback(item: ListItem) {
    WindowOpen.tab(item.stripeRegistUrl);
  }

  /**
   * テーブル行のアクションボタンがクリックされた際のコールバック
   *
   * @param item 選択行のListItem
   */
  async actionClickCallback(item: ListItem) {
    WindowOpen.tab(item.stripeDashboardUrl);
  }

  /**
   * 本店店舗情報の一覧を取得する処理
   */
  async fetchMainShopList(): Promise<boolean> {
    if (MainShopGet.isSuccess) {
      return true;
    }

    await MainShopGet.get();
    if (!MainShopGet.isSuccess) {
      await Flash.setErrorNow({
        message: MainShopGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    return MainShopGet.isSuccess;
  }

  async fetchAppNameList(): Promise<boolean> {
    if (AppNameListGet.isSuccess) {
      return true;
    }
    await AppNameListGet.get();
    if (!AppNameListGet.isSuccess) {
      await Flash.setErrorNow({
        message: AppNameListGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    return AppNameListGet.isSuccess;
  }

  /**
   * 検索処理
   */
  async search() {
    this.isLoading = true;
    let isSuccess = await this.fetchMainShopList();
    if (!isSuccess) {
      this.isLoading = false;
      return;
    }

    isSuccess = await this.fetchAppNameList();
    if (!isSuccess) {
      this.isLoading = false;
      return;
    }

    let request = this.createRequest();
    await RegistStripeShopSearch.search(request);
    if (!RegistStripeShopSearch.isSuccess) {
      await Flash.setErrorNow({
        message: RegistStripeShopSearch.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  /**
   * dataから検索用のリクエストを作成する
   */
  private createRequest(): SearchRequest {
    const request = this.tableOptions as SearchRequest;
    if (this.inputOptions.searchShopId) {
      request.searchShopId = this.inputOptions.searchShopId;
    } else {
      delete request.searchShopId;
    }
    if (this.inputOptions.selectShopIds) {
      request.selectShopIds = this.inputOptions.selectShopIds;
    } else {
      delete request.selectShopIds;
    }
    if (this.inputOptions.selectAppShopIds) {
      request.selectAppShopIds = this.inputOptions.selectAppShopIds;
    } else {
      delete request.selectAppShopIds;
    }
    if (this.inputOptions.stripeRegistStatus) {
      request.stripeRegistStatus = this.inputOptions.stripeRegistStatus;
    } else {
      delete request.stripeRegistStatus;
    }

    return request;
  }
}
